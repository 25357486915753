import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XMLHttpRequestInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addHeader(request));
  }

  private addHeader(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  }
}
