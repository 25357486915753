import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, empty, BehaviorSubject, throwError } from 'rxjs';

import { AuthenticationService } from 'core/authentication.service';
import { Router } from '@angular/router';
import { switchMap, catchError, filter, take } from 'rxjs/operators';
import { TokenDto } from 'api/models/token-dto';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(AuthenticationService.SKIP_INTERCEPTOR)) {
      const headers = request.headers.delete(
        AuthenticationService.SKIP_INTERCEPTOR,
        'true'
      );
      return next.handle(request.clone({ headers }));
    }

    request = this.addToken(request, this.authService.authToken);

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.authService.hasRefreshToken) {
      this.router.navigate(['/login']);
      return empty();
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        catchError(err => {
          this.isRefreshing = false;
          if (err instanceof HttpErrorResponse && err.status === 400) {
            // Failed to refresh token
            this.router.navigate(['/login']);
          }
          return throwError(err);
        }),
        switchMap((token: TokenDto) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.authToken);
          return next.handle(this.addToken(request, token.authToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((jwt: TokenDto) => {
          return next.handle(this.addToken(request, jwt.authToken));
        })
      );
    }
  }
}
